<template>
  <div class="wrapper">
    <div class="box">
      <h2>乐票闪贴隐私政策</h2>
      <p>
        本政策仅适用于北京联想海纳支付有限公司及其关联公司（以下或称“我们”或“海纳支付”）提供的产品和服务及其延伸的功能。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用；该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。
      </p>
      <p class="bold">引言</p>
      <p class="bold">
        我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的信息。我们希望通过《乐票闪贴隐私政策》（“本政策”）向您说明我们的业务情形和对应需要收集您个人信息的类型，以及我们为您提供访问、更新、这些信息的途径和保护措施，还有当我们共享、转让、公开披露您个人信息时需要遵守的规则。本政策与您所使用的乐票闪贴服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本政策所写明的内容（特别是加粗字体的内容），并让您可以按照本政策的指引做出您认为适当的选择。本政策中涉及的相关术语，我们用简明扼要的表述，以便您更好地理解。
      </p>
      <p class="bold">
        需要特别说明的是，本政策仅适用于本政策定义的产品与/或服务。当您使用第三方机构直接向您提供的产品或服务并向其提供您的个人信息时，我们建议您在使用之前仔细查看他们的隐私政策，了解他们如何处理您的信息，以便审慎决策。
      </p>
      <p class="bold">目录</p>
      <p>一、我们如何收集您的个人信息</p>
      <p>二、我们如何使用您的个人信息</p>
      <p>三、我们如何使用 Token 和同类技术</p>
      <p>四、我们如何委托处理共享、转让、公开披露您的个人信息</p>
      <p>五、我们如何保护您的个人信息</p>
      <p>六、您的权利</p>
      <p>七、本政策如何更新</p>
      <p>八、如何联系我们</p>
      <p class="bold">一、我们如何收集您的个人信息</p>
      <p>
        我们会遵循正当、合法、必要和诚信原则，出于本政策所述的以下目的，收集和使用您在使用产品与/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。
        <span class="bold">
          如果我们要将您的个人信息用于本政策未载明的其它用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
        </span>
      </p>
      <p class="bold">（一）我们如何收集您的信息</p>
      <p>我们会通过如下三种渠道获取您的个人信息：</p>
      <p>
        <span class="bold">1、您直接提供的信息：</span>
        我们可能收集并存储您在使用乐票闪贴服务时向我们提供的信息，包括您在我们页面中在线填写信息时、申请产品与/或服务时、解决争议或就乐票闪贴服务与我们联系时所
        <span class="bold">提供的信息，</span>
        以及您在使用我们服务过程中
        <span class="bold">产生的关于您的交易和活动的信息。</span>
      </p>
      <p>
        <span class="bold">2、我们主动收集的信息：</span>
        在您使用乐票闪贴服务过程中，我们会收集您的计算机、手机及其他访问设备发送给我们的信息。
      </p>
      <p>
        <span class="bold">3、我们从其他来源取得的信息：</span>
        在取得您同意的前提下，我们还可能从联想海纳支付关联公司及合作伙伴、信用机构及依法成立并合法留存您的相关信息的第三方机构获取您的相关信息。
      </p>
      <p class="bold">（二）我们收集您的哪些信息</p>
      <p>
        在实现乐票闪贴业务功能的过程中，我们需要收集您的一些信息，用以向您提供服务、提升我们的服务质量、保障您的账号和资金安全以及符合国家法律法规及监管规定。
        <span class="bold">
          请您注意，如果您提供的是他人个人信息，请您确保已取得相关主体的授权。
        </span>
      </p>
      <p class="bold">1、账号注册与登录</p>
      <p>
        乐票闪贴中的账号登录服务由联想海纳支付有限公司（以下简称“海纳支付”）提供。在此过程中，为对您的账号进行验证以实现登录目的，我们将按照《乐票闪贴隐私政策》的约定收集、存储您的
        <span class="bold">账号名、密码、手机号码</span>
        用于账号注册与登录的基础账号信息和您自行选择补充提供的
        <span class="bold">头像、昵称</span>
        等其他账号信息，
      </p>
      <p class="bold">2、实名认证</p>
      <p>
        在您使用乐票闪贴平台时，我们需要您提供营业执照图片，身份证正反面图片，对公银行账户信息。我们会将上述信息提交给合法持有您上述信息的第三方身份验证机构，以便对您所提供信息的准确性进行核对如果您不提供上述信息或您的实名验证未通过，则您无法使用上述服务。
      </p>
      <p class="bold">3、保障交易安全所必须的功能</p>
      <p class="bold">
        （一）我们通过第三方主体或使用第三方服务时间接收集您信息
      </p>
      <p>
        1、我们可能会在您的授权同意范围内从第三方（我们的合作方）处收集并使用您的个人信息。
        <span class="bold">
          我们保证严格依照与第三方签订的合同以及相关法律规定处理您的个人信息，同时请您详细阅读该第三方的隐私政策及用户协议。
        </span>
        如您拒绝第三方在提供服务时收集、使用或者传递您的个人信息，将可能导致您无法使用相应的服务。
      </p>
      <p class="bold">（二）征得授权同意的例外</p>
      <p>
        依据相关法律法规及国家标准，以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意：
      </p>

      <p>1、与国家安全、国防安全直接有关的；</p>
      <p>2、与公共安全、公共卫生、重大公共利益直接有关的；</p>
      <p>3、与犯罪侦查、起诉、审判和判决执行等直接有关的；</p>
      <p>
        4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
      </p>
      <p>5、所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
      <p>6、从合法公开披露的信息中收集的您的个人信息的；</p>
      <p>7、根据您的要求签订和履行合同所必需的；</p>
      <p>
        8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置服务的故障；
      </p>
      <p>9、为履行法定职责或者法定义务所必需；</p>
      <p>10、法律法规规定的其他情形。</p>
      <p class="bold">二、我们如何使用您的个人信息</p>
      <p>
        1、为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账号和资金安全，我们会在以下情形中使用您的信息：
      </p>
      <p>（1）实现本政策“我们收集您的哪些信息”约定的目的。</p>
      <p>（2）根据法律法规或监管要求向相关部门进行报告。</p>
      <p>（3）提高、完善和优化乐票闪贴在线服务。</p>
      <p>（4）处理您对乐票闪贴与/或服务的申请或反馈请求。</p>
      <p>
        （5）我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
      </p>
      <p>
        2、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏展示，以保护您的信息安全。
      </p>
      <p>
        3、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
      </p>
      <p class="bold">三、我们如何使用Token和同类技术</p>
      <p class="bold">（一）Token的使用</p>
      <p>
        1、为使您获得更轻松的访问体验，我们有时会在您的计算机或移动设备上存储名为Token的小数据文件。Token是一种网络服务器存储在计算机或移动设备上的令牌，其内容只能由创建它的服务器检索或读取。每个
        Token对您的网络浏览器或移动应用程序都是唯一的。Token通常包含标识符、用户信息以及会话。作客户端进行请求的一个令牌，当第一次登录后，服务器生成一个Token便将此Token返回给客户端，以后客户端只需带上这个Token前来请求数据即可，无需再次带上用户名和密码。
      </p>
      <p>
        2、为了向您提供更为便捷、个性化的服务，我们通过Token等技术为您提供以下服务：
      </p>
      <p>
        （1）记住您的账号。例如，当您访问一个需要您登录才可以提供的信息或服务，当您登录时，服务端接收到该参数后，便用一个变量来接收同时将其作为Token保存在数据库，并将该Token设置到session中，客户端每次请求的时候都要统一拦截，并将客户端传递的Token和服务器端session中的Token进行对比，如果相同则放行，不同则拒绝
        ，由于是不可逆转的加密存储，其他人即使可以使用该您的计算机，也无法识别出您的登录名。您并不需要额外做任何工作，所有的收集、保存和加密工作都由系统自动完成。Token可能帮助您省去为使用我们服务而重复输入账号的步骤。
      </p>
      <p>
        （2）分析您使用我们服务的情况。例如，我们可通过网络信标等技术来了解您使用我们服务的具体用途，或哪些网页或服务最受您的欢迎。
      </p>
      <p>
        （3）广告优化。我们可能会根据您访问相关网站的统计信息为您显示您可能感兴趣的产品或广告信息。这些统计信息并不包括您的任何个人信息，这些信息可能被用来评估广告的有效度。网络信标等技术有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。
      </p>
      <p>
        （4）我们也可能通过网络信标技术来统计匿名访问的数据，同样，这些统计信息并不包括您的个人信息。
      </p>
      <p class="bold">四、我们如何委托处理、共享、转让、公开披露您的个人信息</p>
      <p class="bold">（一）委托处理</p>
      <p>
        为了提高效率、降低成本，我们可能会委托关联公司或其他专业服务提供商代表我们来处理信息，例如我们会聘请服务提供商为我们提供基础设施技术服务、信贷审批服务、客户支持服务。对我们委托处理个人信息的公司、组织和个人，我们会通过书面协议对委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等予以落实，并以现场审计等方式要求其遵守严格的保密义务并采取有效的保密措施，要求其只能在委托范围内处理您的信息，而不得出于其自身利益使用您的信息。
      </p>
      <p class="bold">（二）共享</p>
      <p>
        <span class="bold">
          我们可能会向关联公司、合作伙伴等第三方共享您的个人信息，以保障为您提供的服务顺利完成；但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。该等第三方无权将共享的个人信息用于任何其他用途。
        </span>
        对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的协议、本政策要求的保密和安全措施来处理个人信息。
      </p>
      <p class="bold">1、业务共享</p>
      <p class="bold">
        （1）为便于我们基于统一的账号体系向您提供服务以及便于您进行统一管理、保障系统和账号安全，您的个人信息可能会在我们和我们的关联公司之间进行必要共享，共享您的个人信息的主体包括:联想（天津）商业保理有限公司。但我们只会共享必要的个人信息，且受本政策中所声明目的的约束；我们的关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。例如，为了我们与关联公司共同向您提供或推荐服务、产品，或为了协助解决您与我们的关联公司之间就交易产生的纠纷，我们会与关联公司共享您的信息。
      </p>
      <p class="bold">
        2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。如果我们在此过程中违反法律法规的规定，给您造成损失的，我们将承担因此引起的相应责任。
      </p>
      <p class="bold">（三）转让</p>
      <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p>1、在获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
      <p>
        2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
      </p>
      <p>
        3、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </p>
      <p class="bold">（四）公开披露</p>
      <p>我们仅会在以下情况下，才会公开披露您的个人信息：</p>
      <p>
        1、获得您的明确同意后，按您明确同意的披露方式披露您所指定的个人信息；
      </p>
      <p>
        2、在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
      </p>
      <p class="bold">
        （五）共享、转让、公开披露个人信息时事先征得授权同意的例外
      </p>
      <p>
        根据相关法律法规及国家标准，以下情形中，我们共享、转让、公开披露您的个人信息不必事先征得您的授权同意：
      </p>
      <p>1、与您履行法律法规规定的义务相关的；</p>
      <p>2、与国家安全、国防安全直接相关的；</p>
      <p>3、与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>4、与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>
        5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
      </p>
      <p>6、您自行向社会公众公开的个人信息；</p>
      <p>
        7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <p class="bold">（六）个人信息的保存年限</p>
      <p>
        我们获取的您的个人信息，将保存至您账号注销之后的一个月，当您的个人信息超出我们所保存的期限后，我们会对您的个人信息进行删除处理。
      </p>
      <p class="bold">五、我们如何保护您的个人信息</p>
      <p class="bold">（一）我们保护您个人信息的技术与措施</p>
      <p>
        我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
      </p>
      <p class="bold">1、数据安全技术措施</p>
      <p>
        我们已采用符合业界标准的安全防护措施以及行业内通行的安全技术来防止您的个人信息遭到未经授权的访问、修改，避免您的个人信息泄露、损坏或丢失：
      </p>
      <p>
        （1）采取加密技术对您的个人信息进行加密存储，我们存储您个人数据的系统已通过PCI
        DSS（第三方支付行业数据安全标准）认证，从安全管理、策略、过程、网络体系结构等诸多方面保障交易及个人信息安全。
      </p>
      <p>
        （2）我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保您的个人信息在传输过程中的安全。
      </p>
      <p>
        （3）在使用您的个人信息时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内的多种数据脱敏技术增强个人信息在使用中的安全性。
      </p>
      <p>
        （4）我们会采用白盒代码安全检测、黑盒安全测试、入侵检测防御技术进行恶意代码防范措施保护您的个人信息安全。
      </p>
      <p class="bold">2、为保护个人信息采取的其他安全措施</p>
      <p>
        （1）我们采用严格的数据访问权限和多重身份认证技术控制和保护个人信息，通过与信息接触者签署严格的保密协议、监控和审计机制来对数据进行全面安全控制，避免数据被违规使用。
      </p>
      <p>
        （2）我们采用代码安全检查、数据访问日志分析技术进行个人信息安全审计。
      </p>
      <p class="bold">3、安全事件处置</p>
      <p>
        （1）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      </p>
      <p>
        （2）为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
      </p>
      <p>
        （3）一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </p>
      <p class="bold">
        （4）如您发现自己的个人信息泄密，尤其是您的账号及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。
      </p>
      <p class="bold">六、您的权利</p>
      <p>
        我们非常重视您对个人信息的关注，按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，尽全力保障您对自己的个人信息行使以下权利：
      </p>
      <p class="bold">（一）访问您的个人信息</p>
      <p>
        1、您有权随时访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
      </p>
      <p>（1）您的账号信息</p>
      <p>电脑网页端操作路径：</p>
      <p>您可以点击网页顶部企业账户，进入“账户中心”，进行账号相关的操作。</p>
      <p>
        2、您有权随时撤回您关于个人信息处理的授权。您撤回个人信息处理授权时，可以联系我们并正式告知我们。
      </p>
      <p class="bold">七、本政策如何更新</p>
      <p>
        1、我们的隐私政策可能变更。未经您明确同意，我们不会削减您依据本政策所应享有的权利。我们会在乐票秒贴平台发布对本政策所做的任何变更。
      </p>
      <p>2、本政策所指的重大变更包括但不限于：</p>
      <p>
        （1）我们的业务和服务模式发生重大变化。如业务功能、处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </p>
      <p>
        （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </p>
      <p>（3）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>
        3、如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。
      </p>
      <p class="bold">八、如何联系我们</p>
      <p>
        1、我们设立了个人信息保护专职部门和个人信息保护专员，如您对本政策或您个人信息的相关事宜有任何疑问、意见或建议，请通过以下方式与我们联系：
      </p>
      <p>（1）进入乐票闪贴首页，联系客服。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'privacy-policy',
}
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  overflow-y: auto;
  .box {
    padding: 70px 0;
    width: 1100px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 30px;

    h2 {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
    }
    .bold {
      font-weight: bold;
    }

    .mb20 {
      margin-bottom: 20px;
    }
  }
}
</style>
